@import "tachyons";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');

@import "./theme.scss";
@import "./engine.scss";

body {
	font-family: "Roboto", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.heading-font {
	font-family: 'Noto Sans', sans-serif;
}

.aaz-photograph {
	-webkit-mask-image: radial-gradient(circle at 50% 50%, black 70%, rgba(0, 0, 0, 0) 50%);
	mask-image: radial-gradient(circle at 50% 50%, black 70%, rgba(0, 0, 0, 0) 50%);
}

@media (prefers-reduced-motion: no-preference) {
	.aaz-photograph {
		animation: aaz-logo-spin infinite 20s linear;
	}
}

.justify-top {
	justify-content: top;
}

.justify-center {
	justify-content: center;
}

.aaz-container-home {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.aaz-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: calc(10px + 2vmin);
	color: #282c34;
}

.App-content {
	min-height: 80vh;
}

.aaz-home {
	img {
    width: 22px;
    height: 22px;
    padding: 5px;
	}
}

.aaz-banner-main {
	background-image: url(../images/bg-1.jpg);
}

.aaz-banner-about {
	background-image: url(../images/about-me.jpg);
}

.aaz-banner-cartrawler {
	background-image: url(../images/about-cartrawler.jpg);
}

.aaz-banner-oracle {
	background-image: url(../images/about-oracle.jpg);
}

.aaz-banner-enventure {
	background-image: url(../images/about-enventure.jpg);
}

.aaz-banner-adventure {
	background-image: url(../images/about-adventure.jpg);
}

.aaz-banner-gallery {
	background-image: linear-gradient(-120deg, #c2d82f 0%, #b6c54c 29%, #a2ab6d 67%, #787875 100%),
	repeating-linear-gradient(-115deg, transparent, transparent 20px, rgba(255,255,255,0.1) 20px, rgba(255,255,255,0.1) 40px),
	repeating-linear-gradient(115deg, transparent, transparent 20px, rgba(255,255,255,0.1) 20px, rgba(255,255,255,0.1) 40px);
}

.aaz-link {
	color: #61dafb;
}

.carousel {
	.aaz-blockquote {
		overflow-y: auto !important;
		@media (--breakpoint-medium) {
			min-height: 500px;
		}
	}
	button span {
		width: 22px;
		height: 22px;
		@media (--breakpoint-not-small) {
			width: auto;
			height: auto;
		}
	}
}

a {
	&.active {
		img {
			border-color: #61dafb;
			fill: #61dafb;
		}		
	}
	&:hover {
		img {
			border-color: #61dafb;
			fill: #61dafb;
		}
	}
}

.title-fade-out {
	opacity: 0;
	animation-name: fade-out;
	animation-duration: 2s;
}

.title-fade-in {
	opacity: 100;
	animation-name: fade-in;
	animation-duration: 2s;
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
